import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';

Vue.http = Vue.prototype.$http = axios;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: require('@/views/Dashboard.vue').default
  },
  {
    path: '/choose-experience',
    name: 'choose-experience',
    component: require('@/views/experience/ChooseExperience.vue').default
  },
  {
    path: '/experience-scene/:videoId',
    name: 'experience-scene',
    props: true,
    component: require('@/views/experience/ExperienceScene.vue').default
  },
  {
    path: '/breathing-exercise',
    name: 'breathing-exercise',
    component: require('@/views/BreathingExercise.vue').default
  },
  {
    path: '/guided-meditation',
    name: 'guided-meditation',
    component: require('@/views/guided-meditation/GuidedMeditationChoose.vue').default
  },
  {
    path: '/guided-session/:instructorId',
    name: 'guided-session',
    props: true,
    component: require('@/views/guided-meditation/GuidedSession.vue').default
  },
  {
    path: '/mdm',
    name: 'mdm',
    component: () => import('@/views/mdm/MdmSetup.vue'),
    meta: {
      headerHide: true
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
