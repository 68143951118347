<style lang="scss">
  .medi-experience-scene{
    $header-height: 64px; //  header height (var does not work in calc)
    $offset-top: 20px;

    &__content{
      margin-top: $header-height;

      &__sounds{
        width: 300px;
        height: calc(100vh - 64px); // header height

        .sounds-panel{
          margin-top: $offset-top;
        }
      }

      &__back, &__timer {
        .icon-wrapper{
          border-radius: 100%;
        }
      }

      &__back {
        height: 90px;
        width: 105px;
        background-color: white;
        border-bottom-left-radius: $small-curve;
        border-top-left-radius: $small-curve;
        @include positioningOffset(absolute, $header-height + $offset-top, 0, unset, unset);
      }

      &__timer{
        width: 215px;
        @include positioningOffset(absolute, $header-height + $offset-top + 110px, 0, unset, unset);
      }
    }
  }
</style>
<template>
  <div class="medi-experience-scene">

    <video id="video-bg"
           :poster="`url(${getAssetUrl(activeVideo.thumbnail)})`"
           @loadeddata="loadedVideo"
           @onerror="videoError"
           @click="$store.dispatch('toggleFullscreenMode')"
           :src="activeVideo.url"
           class="video-bg" muted autoplay loop>
      Your browser does not support HTML video.
    </video>

    <article class="medi-experience-scene__content">
      <section :class="fullscreen ? 'animate__slideOutLeft' : 'animate__slideInLeft'"
              class="medi-experience-scene__content__sounds p-relative animate__animated animate__faster">
        <sounds-panel :stop-all="stopSound" v-if="activeVideo.categories" :video="activeVideo" :video-sounds="activeVideo.sounds"></sounds-panel>
      </section>


      <section @click="goBack()"
           :class="fullscreen ? 'animate__slideOutRight' : 'animate__slideInRight'"
              class="medi-experience-scene__content__back p-relative d-flex justify-center align-center
               pointer animate__animated animate__faster">
        <div class="icon-wrapper purple-background pa-2">
          <v-icon large color="white">
            mdi-reply
          </v-icon>
        </div>
      </section>

      <section :class="fullscreen ? 'animate__slideOutRight' : 'animate__slideInRight'"
              class="medi-experience-scene__content__timer p-relative d-flex justify-left align-center
              pointer animate__animated animate__faster">
        <timer @finished="endSession($event)"></timer>
      </section>


    </article>

    <modal name="relax-test" :width="700" height="auto" :clickToClose="false">
      <relaxed-test :time="time" experience-type="scene experience" :end="true" :redirect="redirect"></relaxed-test>
    </modal>

    <touch-to-start :loading="loading"></touch-to-start>
  </div>
</template>

<script>
  import { Howl } from 'howler';
  import { mapState } from 'vuex';
  import VideoService from '../../services/VideoService';
  import SoundsPanel from '../../components/experienceScene/SoundsPanel';
  import Timer from '../../components/experienceScene/Timer';
  import RelaxedTest from '../../components/modals/RelaxedTest';
  import TouchToStart from '@/components/experienceScene/TouchToStart';

  export default {
    name: 'scene-experience',
    props: {
      videoId: {
        required: true,
        type: String,
      }
    },
    components: {
      SoundsPanel,
      Timer,
      RelaxedTest,
      TouchToStart,
    },
    computed: {
      ...mapState({
        fullscreen: state => state.fullscreenMode
      })
    },
    data() {
      return {
        redirect: 'dashboard',
        videos: VideoService.getVideos(),
        activeVideo: {
          name: '',
          url: '',
          sounds: ''
        },
        carouselMode: true,
        stopSound: false,
        time: null,
        loading: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('SET_START_SOUND', false);
      this.$store.dispatch('setFullscreenMode', false);
      next();
    },
    mounted() {
      let video = VideoService.getVideoById(this.videoId);

      if(video) {
        this.sendLog(`Chose scene: ${video.name}`);
        this.setActiveVideo(video);
      }
    },
    methods: {
      loadedVideo() {
        this.loading = false;
        console.log('can play');
      },
      toggleModal(name, value) {
        if(value) {
          this.$modal.show(name);
        } else {
          this.$modal.hide(name);
        }
      },
      getAssetUrl(url) {
        if(url) {
          return require('../../assets/'+ url);
        }
      },
      setActiveVideo(newVideo){
        let video = document.getElementById('video-bg');
        this.activeVideo = newVideo;
        video.muted = true;
      },
      goBack() {
        this.sendLog('Finished meditation session without timer enabled');
        this.$router.push({name: 'choose-experience'});
      },
      stopSounds() {
        this.stopSound = true; // turn off all sounds
      },
      endSession(time) {
        this.$store.commit('stats/SET_RELAX_TEST_TIMER', time);
        this.stopSounds();

        // play end session sound (bell ding/chime)
        new Howl({
          src: 'https://files.purplemdm.com/meditation/sounds/other/finish.mp3',
          html5: true,
          loop: false,
          autoplay: true
        });

        this.toggleModal('relax-test', true);
      },
      sendLog(description) {
        this.$store.dispatch('stats/sendLog', {
          description: description
        });
      },
      videoError(err) {
        console.log('video failed to load', err);
      }
    },
  }
</script>
