<style lang="scss">
.start-touch-overlay {
  width: 100vw;
  height: 100vh;
  @include positioningOffset(absolute, 0, unset, unset, 0);
  z-index: $z-lv-10;
  color: white;
  font-size: 40pt;
  background-color: rgba(0,0,0,0.7);

  span {
    display: flex;
    @include centerContent();
  }
}
</style>

<template>
  <section :class="start ? 'animate__fadeOut' : 'animate__fadeIn'"
      @click="triggerStart()" class="start-touch-overlay animate__animated">
    <span v-if="!loading">Touch To Start</span>
    <span v-else>Loading experience ...</span>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },
  components: {},
  data() {
    return {
      test: '',
    };
  },
  computed: {
    ...mapState({
      start: state => state.startSound,
    })
  },
  mounted() {
    this.toggleScroll(true);
  },
  methods: {
    toggleScroll(value) {
      document.querySelector('body').classList.toggle('no-scroll', value);
    },
    triggerStart() {
      if (!this.loading) {
        this.$store.commit('SET_START_SOUND', true);
        setTimeout(() => {
          document.querySelector('.start-touch-overlay').classList.add('hide');
          this.toggleScroll(false);
        }, 1000);
      }
    }
  },
};
</script>
