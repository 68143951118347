<style lang="scss">
    .experience-carousel{
        .swiper-container {
            width: 100%;
            height: 35vh;

            .swiper-nav{
                color: white;
                background-color: rgba(0,0,0,0.4);
                border-radius: 60px;
                width: 60px;
                height: 60px;

                &:after{
                    position: relative;
                }

                &.swiper-button-next:after{
                    left: 5px;
                }

                &.swiper-button-prev:after{
                    right: 3px;
                }
            }

            .swiper-slide {
                text-align: center;
                font-size: 18px;
                position: relative;

                &__img-wrapper{
                    height: 75%;

                    img{
                        object-fit: cover;
                        border: solid 4px black;
                        box-sizing: border-box;
                        border-radius: 10px;
                    }
                }

                .active-slide-only{
                    display: none;
                }

                &.swiper-slide-active{
                    .swiper-slide__img-wrapper{
                        background-image: url("../../../assets/images/woodtexture.png");
                        padding: 20px;
                        box-sizing: border-box;
                        border-radius: 10px;
                    }

                    .active-slide-only{
                        display: block;
                    }
                }

                &__name{
                    background-color: rgba(0,0,0,0.5);
                    margin-top: 15px;
                    font-size: 18pt;
                    border-radius: 10px;
                }

                .medi-btn-purple{
                    @include centerContentOffset(-50%, -150%);
                    font-size: 1.2em;
                }
            }
        }

    }

</style>

<template>
    <div class="experience-carousel">
        <div class="swiper-container" v-if="videos.length > 0">
            <div class="swiper-wrapper">
                <div v-for="video in videos" :key="video._id"
                     class="swiper-slide">
                    <div class="swiper-slide__img-wrapper">
                        <img width="100%" height="100%" :src="getAssetUrl(video.thumbnail)" alt="">

                        <v-btn
                                @click="$emit('start-experience', true)"
                                elevation="0" style="border-radius: 10px !important;" class="medi-btn-purple active-slide-only py-1">
                            Start Experience
                        </v-btn>
                    </div>

                    <h3 class="swiper-slide__name white--text active-slide-only">{{video.name}}</h3>
                </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-nav"></div>
            <div class="swiper-button-prev swiper-nav"></div>
        </div>
    </div>
</template>

<script>
  import Swiper from 'swiper/bundle';
  import 'swiper/swiper-bundle.css'; // import Swiper styles

  export default {
    props: {
      videos: {
        required: true,
        type: Array
      }
    },
    components: {},
    data() {
      return {
        swiper: null,
      };
    },
    mounted() {
      this.setUpCarousel();
    },
    methods: {
      setUpCarousel() {
        this.swiper = new Swiper('.swiper-container', {
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 3,
          spaceBetween: 60,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        this.swiper.on('click', (swiper) => {
          if(swiper.clickedSlide) {
            swiper.slideTo(swiper.clickedIndex);
          }
        });

        this.swiper.on('slideChange', (swiper) => {
          this.emitNewVideoIndex(swiper.activeIndex);
        });

        setTimeout(() => {
          this.swiper.update();
        });
      },
      getAssetUrl(url) {
        if(url) {
          return require('../../../assets/'+ url);
        }
      },
      emitNewVideoIndex(index) {
        this.$emit('new-video', index);
      }
    },
  };
</script>
