<style lang="scss">
  .medi-breathing{
    &__content{
      max-width: 680px;
      margin-top: 160px;

      .media-title-white{
        margin-bottom: 20px;
      }
    }
  }
</style>
<template>
  <div class="medi-breathing mb-10">
    <video class="video-bg" :poster="`url(${getAssetUrl(bgVideo.thumbnail)})`"
           autoplay loop muted>
      <source :src="bgVideo.url" type="video/mp4">
      Your browser does not support HTML video.
    </video>

    <section class="medi-breathing__content p-relative mx-auto">
      <h2 class="media-title-white animate__animated animate__fadeIn animate__slow">Breathing Exercise</h2>

      <div class="medi-breathing__content__buttons mx-auto d-flex justify-center">
        <breathe-anim></breathe-anim>
      </div>
    </section>

    <back-button></back-button>
  </div>
</template>

<script>
  import VideoService from '../services/VideoService';
  import BackButton from '../components/backButton/BackButton';
  import BreatheAnim from '../components/breathingExercise/BreathAnim';

  export default {
    name: 'breathing-exercise',
    components: {
      BackButton,
      BreatheAnim
    },
    data() {
      return {
        redirect: '',
        bgVideo: {
          url: '',
          thumbnail: ''
        },
        timer: 0,
        interval: null,
      };
    },
    beforeDestroy() {
      this.finishSession();
    },
    mounted() {
      this.bgVideo = VideoService.getVideoById('woodland1');
      this.startSession();
    },
    methods: {
      getAssetUrl(url) {
        if(url) {
          return require('../assets/'+ url);
        }
      },
      sendLog(description, meta = null) {
        this.$store.dispatch('stats/sendLog', {
          description: description,
          meta: meta
        });
      },
      startSession() {
        this.sendLog('Started Breathing Exercise');
        if(this.interval) {
          clearInterval(this.interval);
        }

        this.interval = setInterval(() => {
          this.timer += 1;
        }, 1000);
      },
      finishSession() {
        clearInterval(this.interval);
        this.sendLog(`Finished Breathing exercise after ${this.timer} seconds`, {sessionTime: this.timer});
      }
    },
  }
</script>
