<style lang="scss">
  .medi-experience{
    &__content{
      max-width: 680px;
      margin-top: 115px;
    }

    &__carousel{
      margin-top: 80px;
    }

    &__view-mode {
      background-color: white;
      width: 60px;
      height: 60px;
      border-radius: $xx-small-curve;
      @include positioningOffset(fixed, unset, unset, 25px, 50%);
      z-index: $z-lv-1;
    }
  }
</style>
<template>
  <div class="medi-experience">

<!--    <video id="video-bg" :poster="`url(${getAssetUrl(activeVideo.thumbnail)})`"-->
<!--           :src="activeVideo.url"-->
<!--           class="video-bg" muted autoplay loop>-->
<!--      Your browser does not support HTML video.-->
<!--    </video>-->
    <div class="background-full" :style="{backgroundImage: `url(${getAssetUrl(activeVideo.thumbnail)})`}"></div>


    <div class="loading-video" :class="{'loading': loadingVideo}"></div>

    <section class="medi-experience__content p-relative mx-auto">
      <h2 class="media-title-white animate__animated animate__fadeIn animate__slow">Choose your experience</h2>
    </section>

    <section :class="carouselMode ? 'animate__fadeIn' : 'animate__fadeOut'"
            class="medi-experience__carousel px-6 animate__animated animate__slow" v-show="carouselMode">
      <experience-carousel @start-experience="startExperience()"
                           @new-video="setActiveVideo($event)" :videos="videos"></experience-carousel>
    </section>

    <section :class="carouselMode ? 'animate__fadeOut' : 'animate__fadeIn'"
            class="med-experience__tile-view px-6 mt-8 animate__animated animate__slow" v-show="!carouselMode">
      <tile-view @start-experience="startExperience()"
                 :active-video="activeVideo" @new-video="setActiveVideo($event)" :videos="videos"></tile-view>
    </section>

    <span @click="carouselMode = !carouselMode"
          class="medi-experience__view-mode p-relative d-flex justify-center align-center mx-auto pointer">
       <v-icon large color="black">
         {{carouselMode ? 'mdi-fullscreen' : 'mdi-fullscreen-exit'}}
        </v-icon>
    </span>
  </div>
</template>

<script>
  import ExperienceCarousel from '../../components/chooseExperience/carousel/ExperienceCarousel';
  import TileView from '../../components/chooseExperience/tileView/TileView';
  import VideoService from '../../services/VideoService';

  export default {
    name: 'choose-experience',
    components: {
      ExperienceCarousel,
      TileView,
    },
    data() {
      return {
        videos: VideoService.getVideos(),
        loadingVideo: false,
        activeVideo: {
          name: '',
          url: ''
        },
        carouselMode: true,
      };
    },
    mounted() {
      this.setActiveVideo(0);
    },
    methods: {
      getAssetUrl(url) {
        if(url) {
          return require('../../assets/'+ url);
        }
      },
      setActiveVideo(videoIndex){
        this.setLoadingVideo(true);
        // let video = document.getElementById('video-bg');
        this.activeVideo = this.videos[videoIndex];
        // video.muted = true;

        setTimeout(() => {
          this.setLoadingVideo(false);
        }, 600);
      },
      setLoadingVideo(value) {
        this.loadingVideo = value;
      },
      startExperience() {
        this.$router.push({name: 'experience-scene', params: { videoId: this.activeVideo._id  }});
      }
    },
  }
</script>
