import axios from 'axios';
// import {REST_ENDPOINT} from '@/util/endpoints';

// const apiClient = axios.create({
//   baseURL: REST_ENDPOINT,
//   withCredentials: false,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   }
// });

export default {
  sendStat(meta, description, electron = false) {
    return new Promise(function (resolve, reject) {
      const activity = {
        action: "MeditationStat",
        description: description,
        meta: JSON.stringify(meta),
        target: "com.madepurple.reflection",
        app_id: "com.madepurple.reflection",
      }

      if (electron) {
        axios.post('electron-log', JSON.stringify(activity));
      } else {
        // eslint-disable-next-line no-undef
        try {
          // eslint-disable-next-line no-undef
          Android.sendActivityForLog(JSON.stringify(activity));
          resolve('sent')
        } catch (e) { console.log('no android found') }
      }

      setTimeout(() => {
        reject('did not submit log');
      }, 10000);

      resolve('did not submit log');
    })
    // return apiClient.post('/', {
    //   action: "SubmittedRelaxScore",
    //   description: "Finished a relaxtion sesssion",
    //   meta: { test: stat, stuff: "whoop it worked!" },
    //   target: "com.purplevisits.reflection"
    // }, {
    //   // headers: {
    //   //   Accept: 'application/json',
    //   //   'Content-Type': 'application/json',
    //   //   'Authorization': 'Bearer: ' + localStorage.getItem('mdm-token')
    //   // }
    // });
  },
};
