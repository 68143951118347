<style lang="scss">
  .guided-meditation{
    &__content{
      max-width: 680px;
      margin-top: 115px;
    }

    &__carousel{
      margin-top: 80px;
    }
  }
</style>
<template>
  <div class="guided-meditation">

    <div class="background-full" :style="{backgroundImage: `url(${getAssetUrl('images/guided-meditation.jpg')})`}"></div>

    <section class="guided-meditation__content p-relative mx-auto">
      <h2 class="media-title-white animate__animated animate__fadeIn animate__slow">Choose an instructor</h2>
    </section>

    <section
            class="guided-meditation__carousel px-6 animate__animated animate__slow animate__fadeIn">
      <instructor-carousel @start-experience="startExperience()"
                           @new-instructor="setActiveInstructor($event)" :instructors="instructors"></instructor-carousel>
    </section>
  </div>
</template>

<script>
  import InstructorCarousel from '../../components/guideMeditation/carousel/InstructorCarousel';
  import GuidedMeditationService from '../../services/GuidedMeditationService';

  export default {
    name: 'guided-meditation-choose',
    components: {
      InstructorCarousel
    },
    data() {
      return {
        instructors: GuidedMeditationService.getInstructors(),
        loading: true,
        activeInstructor: {
          name: '',
          url: ''
        },
      };
    },
    mounted() {
      this.setActiveInstructor(0);
    },
    methods: {
      getAssetUrl(url) {
        if(url) {
          return require('../../assets/'+ url);
        }
      },
      setActiveInstructor(videoIndex){
        this.activeInstructor = this.instructors[videoIndex];

        setTimeout(() => {
          this.setLoading(false);
        }, 600);
      },
      setLoading(value) {
        this.loading = value;
      },
      startExperience() {
        this.$router.push({name: 'guided-session', params: { instructorId: this.activeInstructor._id  }});
      }
    },
  }
</script>
