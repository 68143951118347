import axios from 'axios';

const userTokenKey = 'userTokenMed'; // changed keys as they clashed with portal names locally (used in router also)
// const userKey = 'userMed';
export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    getLocalUserToken() {
      return localStorage.getItem(userTokenKey);
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    // eslint-disable-next-line
    setLocalUserToken({ commit }, token) {
      localStorage.setItem(userTokenKey, token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
  },
};
