<style lang="scss">
.sounds-panel{
  width: 300px;
  height: auto;
  background-color: white;
  @include positioningOffset(absolute, 0, unset, unset, 0);
  border-bottom-right-radius: $small-curve;
  border-top-right-radius: $small-curve;

  &__content{
    &__options {
      height: 490px;
      overflow-y: auto;

      .ui-sound{
        font-size: 1.2rem;
      }
    }
  }
}
</style>

<template>
  <div class="sounds-panel">

    <h2 class="sounds-panel__title title-color title-font font-weight-regular text-center title-divider pb-0">
      Scene Sounds
    </h2>

    <section class="sounds-panel__content pa-4">
      <section class="sounds-panel__content__options">
        <div class="ui-sound d-flex align-center" v-for="sound in sounds" :key="sound._id">
          <p class="mb-0 pb-0">{{sound.name}}</p>
          <v-spacer></v-spacer>
          <div :switchref="sound._id">
            <v-switch
                color="#7B2DA4"
                value="#7B2DA4"
                @click="play(sound)"
                v-model="sound.playing"
                inset
            ></v-switch>
          </div>
        </div>

        <p v-if="sounds.length === 0">No Sounds available</p>
      </section>

      <v-btn @click="resetSoundsToDefault()"
             elevation="0" style="border-radius: 10px !important;" class="sounds-panel__content__reset
             medi-btn-purple btn-small-font full-width mt-3 py-1">Reset to default</v-btn>
    </section>


  </div>
</template>

<script>
import {mapState} from 'vuex';
import Vue from 'vue';
import VideoService from '../../services/VideoService';
import {Howl} from 'howler';

export default {
  props: {
    video: {
      required: true,
      type: Object,
    },
    stopAll: {
      required: false,
      type: Boolean
    }
  },
  components: {},
  watch: {
    stopAll (newVal) {
      if(newVal) {
        this.stopAllSounds();
      }
    },
    start() {
      this.playMainSound();
    }
  },
  data() {
    return {
      sounds: [],
    };
  },
  computed: {
    ...mapState({
      start: state => state.startSound,
    })
  },
  beforeDestroy() {
    this.stopAllSounds();

    window.onAppBackgrounded = null;
    window.onAppForegrounded = null;
  },
  mounted() {
    this.resetSoundsToDefault();

    // Define global functions to be called from the Android app
    window.onAppBackgrounded = this.handleAppBackgrounded;
    window.onAppForegrounded = this.handleAppForegrounded;
  },
  methods: {
    handleAppBackgrounded() {
      this.muteAllSounds();
    },
    handleAppForegrounded() {
      this.unmuteAllSounds();
    },
    setUpSounds() {
      this.sounds.forEach((sound) => {
        Vue.set(sound, 'playing', false); // add playing attribute
        Vue.set(sound, 'howl', null); // add sound attribute (howl obj)
      });

      // this.playMainSound();
    },
    getAssetUrl(url) {
      if(url) {
        return require('../../assets/'+ url);
      }
    },
    playMainSound() {
      setTimeout(() => {
        const mainSoundSwitch = document.querySelector(`[switchref="${this.video.mainSoundId}"] input`);

        if(mainSoundSwitch) {
          mainSoundSwitch.click();
        }
      });
    },
    resetSoundsToDefault() {
      this.stopAllSounds();
      this.sounds = VideoService.getSoundsByCategory(this.video.categories);
      this.setUpSounds();
    },
    play(sound) {
      if(sound.playing) { // play new sound if flag is set to (from switch)
        sound.howl = new Howl({
          // src: this.getAssetUrl(sound.url),
          src: sound.url,
          html5: true,
          loop: true,
          autoplay: true
        });

        sound.howl.play();
      } else {
        sound.howl.stop(); // stop sound
        sound.howl = null; // remove howl object
      }
    },
    stopSound(sound) {
      if(sound.howl) {
        sound.howl.stop(); // stop sound
        sound.howl = null; // remove howl object
      }
    },
    stopAllSounds() {
      this.sounds.forEach((sound) => {
        this.stopSound(sound);
      })
    },
    muteAllSounds() {
      this.sounds.forEach((sound) => {
        if (sound.howl) {
          sound.howl.mute(true);
        }
      });
    },
    unmuteAllSounds() {
      this.sounds.forEach((sound) => {
        if (sound.howl) {
          sound.howl.mute(false);
        }
      });
    }
  },
};
</script>
