import Vue from 'vue';
import Vuex from 'vuex';
import themeService from '../services/themeService';

import StatsModule from './modules/StatsModule';
import AuthModule from '@/store/modules/AuthModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    darkMode: false,
    fullscreenMode: false,
    startSound: false,
    isElectron: false,
  },
  mutations: {
    SET_DARK_MODE(state, value){
      state.darkMode = value;
      themeService.setDarkTheme(value);
    },
    SET_FULLSCREEN_MODE(state, value) {
      state.fullscreenMode = value;
    },
    SET_START_SOUND(state, value) {
      state.startSound = value;
    },
    SET_IS_ELECTRON(state, value) {
      state.isElectron = value;
    },
  },
  actions: {
    setFullscreenMode({commit}, value) {
      commit('SET_FULLSCREEN_MODE', value);
    },
    toggleFullscreenMode({commit, state}) {
      commit('SET_FULLSCREEN_MODE', !state.fullscreenMode);
    }
  },
  modules: {
    stats: StatsModule,
    auth: AuthModule,
  }
});

export default store;
