<template>
  <v-app class="light-theme" :class="{'electron': isElectron}">
    <v-main class="theme-background">
      <app-header v-if="!$route.meta.headerHide"></app-header>
      <v-container fluid class="no-gutters pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import '@/assets/styles/styles.scss';
import AppHeader from './components/AppHeader';
import {mapState} from 'vuex';

export default {
  name: 'App',
  components: {
    AppHeader
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      isElectron: state => state.isElectron,
    })
  },
  mounted() {

  }
};
</script>
