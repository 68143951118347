<style lang="scss">
.experience-tile-view{
    &__tiles{
        max-height: 385px;
        overflow-y: auto;

        &__tile{
            &__img-wrapper{
                height: 150px;
                position: relative;

                .medi-btn-purple{
                    @include centerContent();
                    font-size: 1.2em;
                }

                img{
                    object-fit: cover;
                    border: solid 4px black;
                    box-sizing: border-box;
                    border-radius: 10px;
                }
            }

            &__name{
                background-color: rgba(0,0,0,0.5);
                margin-top: 15px;
                font-size: 18pt;
                border-radius: 10px;
            }
        }
    }
}
</style>

<template>
    <div class="experience-tile-view">
        <section class="experience-tile-view__tiles p-relative row">
            <div class="experience-tile-view__tiles__tile col-3"
                 @click="emitNewVideoIndex(index)"
                 v-for="(video, index) in videos" :key="video._id">
                <div class="experience-tile-view__tiles__tile__img-wrapper pointer">
                    <img width="100%" height="100%" :src="getAssetUrl(video.thumbnail)" alt="">
                    <v-btn v-if="activeVideo._id === video._id"
                           @click="$emit('start-experience', true)"
                            elevation="0" style="border-radius: 10px !important;" class="medi-btn-purple py-1">Start Experience</v-btn>
                </div>

                <h3 class="experience-tile-view__tiles__tile__name text-center white--text pointer">{{video.name}}</h3>
            </div>
        </section>
    </div>
</template>

<script>
  export default {
    props: {
      videos: {
        required: true,
        type: Array
      },
      activeVideo: {
        required: true,
        type: Object
      }
    },
    components: {},
    data() {
      return {
        test: '',
      };
    },
    mounted() {

    },
    methods: {
      getAssetUrl(url) {
        if(url) {
          return require('../../../assets/'+ url);
        }
      },
      emitNewVideoIndex(index) {
        this.$emit('new-video', index);
      }
    },
  };
</script>
