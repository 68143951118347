<style lang="scss">
    .v--modal-box{
        //transform: translateY(-60px);
    }
    .relaxed-test-modal{
        &__content{
            .ui-title{
                max-width: 500px;
                line-height: 1;
            }

            .ui-slider{
                padding: 100px 43px;

                .v-slider__thumb{
                    width: 20px !important;
                    height: 20px !important;
                    left: -10px !important;

                    &:before{
                        left: -9px !important;
                        top: -9px !important;
                    }
                }

                .v-slider__track-container{
                    height: 5px !important;
                }

                .v-slider__tick-label{
                    font-size: 15pt;
                    top: -32px !important
                }

                .v-slider__tick{
                    top: calc(50% - 23px) !important;
                    height: 25px !important;

                    &:last-child, &:first-child{
                        .v-slider__tick-label{
                            transform: translateX(-50%) !important;
                        }
                    }
                }

                .v-slider__tick--filled, .v-slider__tick{
                    background-color: $purple-m !important;
                }
            }

        }
    }
</style>

<template>
    <div class="relaxed-test-modal">
        <section class="relaxed-test-modal__content pa-8">
            <h2 class="title-color title-divider title-font text-center mx-auto ui-title">
                {{!end ? 'Before we start' : 'After this session'}} how relaxed do you feel?
            </h2>

            <section class="ui-slider">
                <v-slider
                        v-model="test.stressLevel"
                        :tick-labels="ticksLabels"
                        :max="4"
                        step="1"
                        ticks="always"
                        tick-size="8"
                        :thumb-size="200"
                        :color="purpleColor"
                        :thumb-color="purpleColor"
                        :track-fill-color="purpleColor"
                        :track-color="'B6B6B6'"
                ></v-slider>
            </section>

            <v-btn @click="submitTestData()"
                    class="medi-btn-purple btn-font pa-4 full-width" elevation="0">
                {{!end ? 'Start unwinding process' : 'Finish Session'}}
            </v-btn>
        </section>
    </div>
</template>

<script>
  export default {
    props: {
      redirect: {
        required: true,
        type: String
      },
      end: {
        required: false,
        type: Boolean,
      },
      experienceType: {
        required: true,
        type: String
      },
      time: {
        required: false,
        type: Number
      }
    },
    components: {},
    data() {
      return {
        test: {
          stressLevel: 0
        },
        purpleColor: '#910ED6',
        ticksLabels: [
          'Feel stressed',
          'Bit Tense',
          "I'm okay",
          'Pretty Calm',
          'Fully Relaxed',
        ],
      };
    },
    mounted() {
      if(!this.end) {
        this.$store.commit('stats/RESET', true);
      }
    },
    methods: {
      submitTestData() {
        const stressLevel = this.ticksLabels[this.test.stressLevel];

        if (this.end) {
          this.$store.dispatch('stats/setRelaxTestAfter', {stressLevel: stressLevel, experienceType: this.experienceType, time: this.time});
        } else {
          this.$store.dispatch('stats/setRelaxTestBefore', {stressLevel: stressLevel, experienceType: this.experienceType, time: this.time});
        }

        this.goToRedirect();
      },
      goToRedirect() {
        this.$router.push({name: this.redirect});
      }
    },
  };
</script>
