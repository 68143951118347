<style lang="scss">
    .instructor-panel{
        width: 300px;
        height: auto;
        background-color: white;
        @include positioningOffset(absolute, 0, unset, unset, 0);
        border-bottom-right-radius: $small-curve;
        border-top-right-radius: $small-curve;

        &__content{
            &__options {
                max-height: 490px;
                overflow-y: auto;

                .ui-sound{
                    font-size: 1.2rem;

                    &__icons {
                        span {
                            @include flex(35px, flex, center, center);
                            height: 35px;
                            background-color: $purple-m;
                            border-radius: 20px;

                            &.tick {
                                background-color: $green;
                            }

                            i {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<template>
    <div class="instructor-panel" v-if="instructor">
        <h2 class="instructor-panel__title title-color title-font font-weight-regular text-center title-divider pb-0">
            Sessions
        </h2>

        <section class="instructor-panel__content pa-4">
            <section class="instructor-panel__content__options">
                <div class="ui-sound d-flex align-center mb-2" v-for="session in instructor.sessions" :key="session._id">
                    <p class="mb-0 pb-0 text-capitalize">{{session.name}}</p>
                    <v-spacer></v-spacer>
                    <div class="ui-sound__icons d-flex">
                        <span class="tick mr-4" v-if="session.complete"><i class="fas fa-check"></i></span>
                        <span @click="pause()" v-if="session.url === playing.url && !playing.howl.paused"><i class="fas fa-pause"></i></span>
                        <span @click="pause()" v-if="session.url === playing.url && playing.howl.paused"><i class="fas fa-play"></i></span>
                        <span @click="play(session.url)" v-if="session.url !== playing.url"><i class="fas fa-play"></i></span>
                    </div>
                </div>

                <p v-if="instructor.sessions.length === 0">No Sessions available</p>
            </section>

            <v-btn @click="endSession()"
                   elevation="0" style="border-radius: 10px !important;" class="instructor-panel__content__reset
             medi-btn-purple btn-small-font full-width mt-3 py-1">Finish Session</v-btn>
        </section>


    </div>
</template>

<script>
  import Vue from 'vue';
  import {Howl} from 'howler';

  export default {
    props: {
      instructor: {
        required: true,
        type: Object,
      },
      stopAll: {
        required: false,
        type: Boolean
      }
    },
    components: {},
    watch: {
      stopAll (newVal) {
        if(newVal) {
          this.stopSound();
        }
      },
    },
    data() {
      return {
        playing: {
          url: '',
          howl: null,
        },
      };
    },
    beforeDestroy() {
      this.stopSound();

      window.onAppBackgrounded = null;
      window.onAppForegrounded = null;
    },
    mounted() {
      // Define global functions to be called from the Android app
      window.onAppBackgrounded = this.handleAppBackgrounded;
      window.onAppForegrounded = this.handleAppForegrounded;
    },
    methods: {
      handleAppBackgrounded() {
        this.pause();
      },
      handleAppForegrounded() {
        this.pause();
      },
      getAssetUrl(url) {
        if(url) {
          return require('../../assets/'+ url);
        }
      },
      endSession() {
        this.$emit('finish', true);
      },
      play(url) {
        let newSound = url !== this.playing.url;

        if(newSound) {
          this.stopSound(); // stop playing all existing sounds

          this.playing.url = url;

          this.playing.howl = new Howl({
            // src: this.getAssetUrl(url),
            src: url,
            html5: true,
            loop: false,
          });
        }

        this.playing.howl.play();
      },
      pause() {
        if(this.playing.howl) {
          if(!this.playing.howl.paused) {
            Vue.set(this.playing.howl, 'paused', true);
            this.playing.howl.pause(); // stop sound
          } else {
            Vue.set(this.playing.howl, 'paused', false);
            this.playing.howl.play();
          }
        }
      },
      stopSound() {
        if(this.playing.howl) {
          this.playing.url = '';
          this.playing.howl.stop(); // stop sound
          this.playing.howl = null; // remove howl object
        }
      },
    },
  };
</script>
