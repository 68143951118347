<style lang="scss">

</style>

<template>
    <div class="countdown-comp text-center">{{timeRemaining}}</div>
</template>

<script>
  import moment from 'moment';

  export default {
    components: {},
    props: {
      date: {
        required: true
      }
    },
    data: function(){
      return{
        clockIntervalId: '',
        timeRemaining: '',
      }
    },
    beforeDestroy() {
      clearInterval(this.clockIntervalId);
    },
    mounted: function () {
      this.fetchTimeRemaining();
      this.pollTimeRemaining();
    },
    methods: {
      pollTimeRemaining() {
        this.clockIntervalId = setInterval(() => {
          this.fetchTimeRemaining();
        }, 1000);
      },
      fetchTimeRemaining() {
        // Get time remaining
        let duration = moment.duration(this.date.diff(moment()));
        let hoursLeft = duration._data.hours;
        let minutesLeft = duration._data.minutes;
        let secondsLeft = duration._data.seconds;

        let secondsFormat = (secondsLeft < 10) ? '0' + secondsLeft : secondsLeft;
        this.timeRemaining = minutesLeft + ':' + secondsFormat;
        // Check if the countdown has ended
        if (hoursLeft <= 0 && minutesLeft <= 0 && secondsLeft <= 0) {
          this.$emit('ended', true);
        }
      },
    }
  }
</script>
