import StatsService from '../../services/StatsService';

export default {
  namespaced: true,
  state: {
    stats: [],
    relaxTest: {
      before: null,
      after: null,
      timer: null
    },
  },
  getters: {},
  mutations: {
    SET_STATS(state, value) {
      state.stats = value;
    },
    SET_RELAX_TEST_BEFORE(state, test) {
      state.relaxTest.before = test;
    },
    SET_RELAX_TEST_AFTER(state, test) {
      state.relaxTest.after = test;
    },
    SET_RELAX_TEST_TIMER(state, time) {
      state.relaxTest.timer = time;
    },
    RESET(state) {
      state.relaxTest.before = null;
      state.relaxTest.after = null;
      state.relaxTest.timer = null;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    setRelaxTestBefore({ commit, state, rootState }, test) {
      commit('SET_RELAX_TEST_BEFORE', test.stressLevel);
      StatsService.sendStat(state.relaxTest, `Started ${test.experienceType} feeling: ${state.relaxTest.before}`, rootState.isElectron);
    },
    setRelaxTestAfter({ commit, state, rootState }, test) {
      commit('SET_RELAX_TEST_AFTER', test.stressLevel);
      StatsService.sendStat(state.relaxTest, `Ended ${test.experienceType} relax test feeling: ${state.relaxTest.after}`, rootState.isElectron);
    },
    // eslint-disable-next-line no-unused-vars
    sendLog({state, rootState}, log) {
      // console.log('log', log);
      StatsService.sendStat(log.meta || {}, log.description, rootState.isElectron);
    }
  }
};
