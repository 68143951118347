<style lang="scss">
    .ui-back-btn{
        $header-height: 64px; //  header height (var does not work in calc)
        $offset-top: 20px;

        height: 90px;
        width: 105px;
        background-color: white;
        border-bottom-left-radius: $small-curve;
        border-top-left-radius: $small-curve;
        @include positioningOffset(absolute, $header-height + $offset-top, 0, unset, unset);

        .icon-wrapper{
            border-radius: 100%;
        }
    }
</style>

<template>
    <section @click="goBack()"
             class="ui-back-btn p-relative d-flex justify-center align-center
               pointer">
        <div class="icon-wrapper purple-background pa-2">
            <v-icon large color="white">
                mdi-reply
            </v-icon>
        </div>
    </section>
</template>

<script>
  export default {
    props: [''],
    components: {},
    data() {
      return {
        test: '',
      };
    },
    mounted() {

    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
</script>
