<style lang="scss">
  .medi-dashboard{
    &__content{
      max-width: 680px;
      margin-top: 160px;

      .media-title-white{
        margin-bottom: 80px;
      }

      &__buttons{
        max-width: 60%;
      }
    }
  }
</style>
<template>
  <div class="medi-dashboard mb-10">
    <video class="video-bg" autoplay loop muted :poster="`url(${getAssetUrl(bgVideo.thumbnail)})`">
      <source :src="bgVideo.url"
              type="video/mp4">
      Your browser does not support HTML video.
    </video>

    <section class="medi-dashboard__content p-relative mx-auto">
      <h2 class="media-title-white animate__animated animate__fadeIn animate__slow">Choose a meditative pathway</h2>

      <div class="medi-dashboard__content__buttons mx-auto animate__animated animate__fadeIn animate__slower">
        <v-btn @click="startExperience()"
               class="medi-btn-transparent full-width title-font pa-4 mb-12" elevation="0">Scene Experience</v-btn>
        <v-btn :to="{name: 'breathing-exercise'}"
                class="medi-btn-transparent full-width title-font pa-4 mb-12" elevation="0">Breathing Exercise</v-btn>
        <v-btn @click="startGuidedMeditation()"
                class="medi-btn-transparent full-width title-font pa-4" elevation="0">Guided Meditation</v-btn>
      </div>

    </section>

    <modal name="relax-test" :width="700" height="auto">
      <relaxed-test :experience-type="experienceType" :redirect="redirect"></relaxed-test>
    </modal>
  </div>
</template>

<script>
  import RelaxedTest from '../components/modals/RelaxedTest';
  import VideoService from '../services/VideoService';

  export default {
    name: 'dashboard',
    components: {
      RelaxedTest
    },
    data() {
      return {
        redirect: '',
        experienceType: '',
        bgVideo: {
          url: '',
          thumbnail: ''
        }
      };
    },
    mounted() {
      this.bgVideo = VideoService.getVideoById('woodland1');
    },
    methods: {
      toggleModal(name, value) {
        if(value) {
          this.$modal.show(name);
        } else {
          this.$modal.hide(name);
        }
      },
      startExperience() {
        this.redirect = 'choose-experience';
        this.experienceType = 'scene experience';
        this.toggleModal('relax-test', true);
      },
      startGuidedMeditation() {
        this.redirect = 'guided-meditation';
        this.experienceType = 'guided meditation';
        this.toggleModal('relax-test', true);
      },
      getAssetUrl(url) {
        if(url) {
          return require('../assets/'+ url);
        }
      }
    },
  }
</script>
