<style lang="scss">
    .breath-anim {
        max-width: 400px;
        position: relative;
        transform: scale(0.5);
        transition: transform 5s ease;

        &:after {
            width: 100%;
            text-align: center;
            content: "Breathe In";
            font-size: 30pt;
            color: white;
            @include centerContent();
        }

        &.out {
            transform: scale(1);
        }

        &.out:after{
            content: "Breathe Out"
        }
    }
</style>

<template>
    <div class="breath-anim" :class="{'out': out}">
        <svg width="100%" height="100%" viewBox="0 0 287 287" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="circle" class="a-circle" transform="matrix(1,0,0,1,-625.667,-384)">
        <circle cx="769.167" cy="527.5" r="143.5" style="fill:rgb(122,45,163);"/>
        <clipPath id="_clip1">
            <circle cx="769.167" cy="527.5" r="143.5"/>
        </clipPath>
        <g clip-path="url(#_clip1)">
            <g transform="matrix(1,0,0,1,252.851,336.125)">
<!--                <text id="text" x="438.313px" y="202.2px" style="font-family:'BerlinSansFB-Reg', 'Berlin Sans FB', sans-serif;font-size:36px;fill:white;">Breathe In</text>-->
            </g>
        </g>
    </g>
</svg>

    </div>
</template>

<script>
  export default {
    props: [''],
    components: {},
    data() {
      return {
        out: false,
        animInterval: null,
        animateTimeout: null,
      };
    },
    beforeDestroy() {
      clearInterval(this.animInterval);
      clearTimeout(this.animateTimeout);
    },
    mounted() {
      this.animateTimeout = setTimeout(() => {
        this.out = !this.out;
        this.startAnimation();
      }, 500);
    },
    methods: {
        startAnimation() {
          this.animInterval = setInterval(() =>{
            this.out = !this.out;
          }, 1000 * 5);
        }
    },
  };
</script>
