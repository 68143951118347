<style lang="scss">
    .timer-panel {
        width: 100%;
        border-bottom-left-radius: $small-curve;
        border-top-left-radius: $small-curve;;
        background-color: white;
        transform: translateX(51%);
        transition: transform 0.2s ease;

        &.active{
            transform: translateX(0);
        }

        &__icon{
            h4{
                font-size: 1.3em !important;
            }
        }

        .clock-custom{
            font-size: 2rem;

            &__title{
                font-size: 1.2rem;
            }

            &__time{
                width: 35px;
            }
        }

        .countdown-section{
            font-size: 3rem;
        }
    }
</style>

<template>
    <div class="timer-panel py-4 px-7" :class="active ? 'active' : ''">
        <div @click="active = !active"
             class="timer-panel__icon d-flex align-center justify-left">
            <div class="icon-wrapper purple-background pa-2">
                <v-icon large color="white">
                    mdi-alarm
                </v-icon>
            </div>

            <h4 v-if="active" class="mb-0 ml-8 title-color">Timer</h4>
        </div>


        <section v-show="active"
                 :class="active ? 'animate__fadeIn' : 'animate__fadeOut'"
                 class="timer-panel__content animate__animated animate__faster">

            <section v-if="!date" class="clock-custom d-flex align-center justify-center">
                <p class="title-color clock-custom__title mb-0 mr-6">Minutes</p>
                <div class="ml-1 d-flex flex-column align-center clock-custom__time">
                    <i @click="increaseTimeSlot()" :class="{'disabled': timer >= 120}"
                       class="fas fa-chevron-up d-block"></i>
                    <span class="d-block title-color">{{timer}}</span>
                    <i @click="decreaseTimeSlot()" :class="{'disabled': timer === 5}"
                       class="fas fa-chevron-down d-block"></i>
                </div>
            </section>

            <section class="countdown-section" v-else>
                <Countdown @ended="stopTimer(true)" :date="date"></Countdown>
            </section>

            <v-btn v-if="!date" @click="startTimer()" elevation="0" class="mt-4 py-1 medi-btn-purple btn-small-font full-width"
                   style="border-radius: 10px !important;">Start Timer</v-btn>
            <v-btn v-else @click="stopTimer()" elevation="0" class="mt-4 py-1 medi-btn-purple btn-small-font full-width"
                   style="border-radius: 10px !important;">Stop Timer</v-btn>
        </section>
    </div>

</template>

<script>
    import moment from 'moment';

    import Countdown from '../countdown/Countdown';
  export default {
    props: [''],
    components: {
      Countdown
    },
    data() {
      return {
        active: false,
        timer: 5, // value to set timer to
        date: null
      };
    },
    mounted() {

    },
    methods: {
      increaseTimeSlot(){
        this.timer += 5;
      },
      decreaseTimeSlot(){
        this.timer -= 5;
      },
      startTimer() {
        this.date = new moment().add(this.timer, 'minutes');
        // this.date = new moment().add(2, 'seconds'); // uncomment for testing
        this.sendLog(`Started timed scene session for ${this.timer} minutes`);
      },
      stopTimer(timerFinishedNaturally) {
        this.date = null;

        if(timerFinishedNaturally) {
          this.$emit('finished', this.timer);
        }
      },
      sendLog(description) {
        this.$store.dispatch('stats/sendLog', {
          description: description
        });
      },
    },
  };
</script>
