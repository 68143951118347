<style lang="scss">
  .guided-session{
    $header-height: 64px; //  header height (var does not work in calc)
    $offset-top: 20px;

    &__content{
      margin-top: $header-height;

      &__sounds{
        width: 300px;
        height: calc(100vh - 64px); // header height

        .instructor-panel{
          margin-top: $offset-top;
        }
      }

      &__back {
        .icon-wrapper{
          border-radius: 100%;
        }
      }

      &__back {
        height: 90px;
        width: 105px;
        background-color: white;
        border-bottom-left-radius: $small-curve;
        border-top-left-radius: $small-curve;
        @include positioningOffset(absolute, $header-height + $offset-top, 0, unset, unset);
      }
    }
  }
</style>
<template>
  <div class="guided-session">

    <div class="background-full" :style="{backgroundImage: `url(${getAssetUrl('images/guided-meditation.jpg')})`}"></div>

    <article class="guided-session__content">
      <section
              class="guided-session__content__sounds p-relative animate__animated animate__faster">
        <session-panel @finish="endSession()"
                :stop-all="stopSound" v-if="activeInstructor" :instructor="activeInstructor"></session-panel>
      </section>


      <section @click="goBack()"
              class="guided-session__content__back p-relative d-flex justify-center align-center
              animate__slideInRight
               pointer animate__animated animate__faster">
        <div class="icon-wrapper purple-background pa-2">
          <v-icon large color="white">
            mdi-reply
          </v-icon>
        </div>
      </section>

    </article>

    <modal name="relax-test" :width="700" height="auto" :clickToClose="false">
      <relaxed-test experience-type="Guided Session" :end="true" :redirect="redirect"></relaxed-test>
    </modal>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { Howl } from 'howler';
  import GuidedMeditationService from '../../services/GuidedMeditationService';
  import SessionPanel from '../../components/guideMeditation/SessionPanel';
  import RelaxedTest from '../../components/modals/RelaxedTest';

  export default {
    name: 'guidedsession',
    props: {
      instructorId: {
        required: true,
        type: String,
      }
    },
    components: {
      SessionPanel,
      RelaxedTest,
    },
    computed: {},
    data() {
      return {
        redirect: 'dashboard',
        instructors: GuidedMeditationService.getInstructors(),
        activeInstructor: null,
        stopSound: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('setFullscreenMode', false);
      next();
    },
    mounted() {
      let instructor = GuidedMeditationService.getInstructorById(this.instructorId);

      if(instructor) {
        this.setActiveInstructor(instructor);
        this.sendLog(`Started a guided mediation session with ${instructor.name}`, {instructor: instructor});
      }
    },
    methods: {
      toggleModal(name, value) {
        if(value) {
          this.$modal.show(name);
        } else {
          this.$modal.hide(name);
        }
      },
      getAssetUrl(url) {
        if(url) {
          return require('../../assets/'+ url);
        }
      },
      setActiveInstructor(newInstructor){
        this.activeInstructor = newInstructor;
      },
      goBack() {
        this.$router.push({name: 'guided-meditation'});
      },
      stopSounds() {
        this.stopSound = true; // turn off all sounds
      },
      endSession() {
        this.stopSounds();
        // play end session sound (bell ding/chime)
        // new Howl({
        //   src: this.getAssetUrl('sounds/other/finish.wav'),
        //   html5: true,
        //   loop: false,
        //   autoplay: true
        // });

        this.toggleModal('relax-test', true);
      },
      sendLog(description, meta = null) {
        this.$store.dispatch('stats/sendLog', {
          description: description,
          meta: meta
        });
      },
    },
  }
</script>
