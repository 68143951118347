let Videos = require('../components/mediaJson/videos');
let Sounds = require('../components/mediaJson/sounds');

export default {
  getVideos() {
    return Videos;
  },
  getVideoById(id) {
    return Videos.find((video) => {
      return video._id === id;
    });
  },
  getSounds() {
    return Sounds;
  },
  getSoundById(id) {
    return Sounds.find((sound) => {
      return sound._id === id;
    });
  },
  getSoundsByCategory(categories) {
    let sounds = [];

    Sounds.forEach((sound) => {
      let exists = sound.categories.find((cat) => {
        return categories.find((passedCat) => {
          return passedCat === cat;
        });
      });

      if(exists) {
        sounds.push(sound);
      }
    });

    return sounds;
  }
}
