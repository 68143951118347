import Vue from 'vue'
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';

import router from './router';
import VModal from 'vue-js-modal';

Vue.config.productionTip = false;
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
