<style lang="scss">
    .app-header{
        width: 100vw;

        .dark-mode-switch{
            i{
                color: black;
            }

            &.dark i{
                color: white;
            }

            .v-messages{
                display: none;
            }

            .v-input__slot{
                margin-bottom: 0;
            }
        }

        &__title p{
            font-size: 15pt;
        }
    }
</style>

<template>
    <v-app-bar :class="fullscreen ? 'animate__slideOutUp' : 'animate__slideInDown'"
            class="app-toolbar app-header theme-background animate__animated animate__faster" fixed dense height="64px" max-height="64px">
        <div class="d-flex align-center app-header__title" @click="backToDashboard()">
<!--            <img width="60px" src="../assets/logo.png" alt="">-->
            <p class="ml-4 mb-0 primary-dark">Meditation</p>
        </div>

      <v-btn v-if="isAndroid" @click="closeApp()" class="ml-6 btn-purple font-weight-bold btn-quit">
        Quit
      </v-btn>

        <v-spacer></v-spacer>

        <h2 class="title-color font-weight-regular" v-if="$route.name === 'experience-scene'">Touch scene for full immersion</h2>

<!--        <div class="mr-6 dark-mode-switch font-weight-bold" :class="{'dark': darkMode}">-->
<!--            <v-switch-->
<!--                    color="white"-->
<!--                    v-model="darkMode"-->
<!--                    inset-->
<!--                    :label="darkMode ? 'Dark' : 'Light'"-->
<!--            >-->
<!--                <template slot="label">-->
<!--                    <v-icon v-if="!darkMode">fas fa-sun</v-icon>-->
<!--                    <v-icon v-if="darkMode">fas fa-moon</v-icon>-->
<!--                </template>-->
<!--            </v-switch>-->
<!--        </div>-->

    </v-app-bar>
</template>

<script>
  import { mapState } from 'vuex';
  import {Howler} from 'howler';

  export default {
    name: 'App-Header',
    props: [''],
    components: {},
    computed: {
      ...mapState({
        fullscreen: state => state.fullscreenMode
      }),
      darkMode: {
        get () {
          return this.$store.state.darkMode
        },
        set (value) {
          this.$store.commit('SET_DARK_MODE', value)
        }
      },
      isAndroid() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1;
      }
    },
    data() {
      return {
        dark: false
      };
    },
    mounted() {

    },
    methods: {
        backToDashboard() {
          console.log('called back');
          this.$router.push({name: 'dashboard'});
        },
        closeApp() {
          Howler.unload(); // stop all music
          // eslint-disable-next-line no-undef
          Android.quit();
        },
    },
  };
</script>
