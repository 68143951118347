let Instructors = require('../components/mediaJson/instructors.json');

export default {
  getInstructors() {
    return Instructors;
  },
  getInstructorById(id) {
    return Instructors.find((item) => {
      return item._id === id;
    });
  },
}
